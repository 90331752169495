import React from "react"
import styles from "./content.module.scss"
import Container from "../../components/container/container"
import imageOne from "../../images/abouttop.png"
import imageTwo from "../../images/about3.jpg"
import { Link } from "gatsby"
const Content = props => {
  return (
    <div>
      <div className={styles.content}>
        <Container>
          <div className={styles.content__wrap}>
            <div
              className={styles.content__text}
              data-sal="slide-right"
              data-sal-duration="1000">
              <h2>{props.heading}</h2>
              <p>{props.body}</p>
            </div>
            <div
              className={styles.content__topImage}
              data-sal="slide-left"
              data-sal-duration="1000">
              <img src={imageOne} alt="test image" />
            </div>
          </div>
          <div
            className={styles.content__wrap}
            data-sal="slide-up"
            data-sal-duration="1000">
            <img src={imageTwo} alt="test image" />
            <div className={styles.content__wrap__cta}>
              <Link to="/contact">Start your project ></Link>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Content
