import React, { useState } from "react"
import { graphql } from "gatsby"
import Banner from "../components/banner/banner"
import Footer from "../components/footer/footer"
import RecentWork from "../components/recent-work/recent-work"
import Testimonial from "../components/testimonial/testimonial"
import Content from "../components/about/content"
import Nav from "../components/navigation/navigation"
import SEO from "../components/seo"

const AboutPage = ({ data }) => {
  const [showNav, setShowNav] = useState(false)
  const bgClass = "about"

  return (
    <div>
      <SEO title="About Us" />
      <Banner
        logo={data.strapiGlobal.logo.publicURL}
        email={data.strapiGlobal.email}
        title={data.strapiAbout.Heading}
        summary={data.strapiAbout.SubHeading}
        image={data.strapiAbout.BannerImage.publicURL}
        bgClass={bgClass}
        openNav={() => setShowNav(true)}></Banner>
      {showNav ? (
        <Nav
          logo={data.strapiGlobal.logo.publicURL}
          close={() => setShowNav(false)}></Nav>
      ) : null}
      <Content
        heading={data.strapiAbout.ContentHeading}
        body={data.strapiAbout.ContentBody}></Content>
      <RecentWork
        data={data.allStrapiCaseStudies.edges}
        heading="Some of our latest projects"
        limit={3}></RecentWork>
      <Testimonial bgClass={bgClass}></Testimonial>
      <Footer
        logo={data.strapiGlobal.logo.publicURL}
        email={data.strapiGlobal.email}></Footer>
    </div>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query AboutQuery {
    allStrapiCaseStudies {
      edges {
        node {
          id
          Title
          Slug
          FeaturedImage {
            publicURL
          }
          Thumbnail {
            publicURL
          }
        }
      }
    }
    strapiGlobal {
      email
      logo {
        publicURL
      }
    }
    strapiAbout {
      Heading
      SubHeading
      ContentHeading
      ContentBody
      BannerImage {
        publicURL
      }
    }
  }
`
