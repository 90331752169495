import React from "react"
import styles from "./recent-work.module.scss"
import Container from "../../components/container/container"
import { Link } from "gatsby"

const RecentWork = props => {
  const limit = props.limit || 3
  const data = props.data.slice(0, limit)
  console.log(data)
  return (
    <div className={styles.recent}>
      <Container>
        <h2 data-sal="slide-right" data-sal-duration="1000">
          {props.heading}
        </h2>
        <div className={styles.recent__work}>
          {data.map((item, key) => (
            <div className={styles.recent__work__item} key={key}>
              <Link to={`/${item.node.Slug}`}>
                <img
                  src={item.node.FeaturedImage.publicURL}
                  alt={item.node.Title}
                />
              </Link>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default RecentWork
